/**
 * ajax通信の仕様について。
 * 2重サブミット防止のため、ajaxの処理中は自動的に画面をロックします。
 * エラー発生時は自動的にロック解除されますが、成功時は解除されません。
 * 明示的に解除する場合は、np_jp.unlock()を呼び出してください。
 */

ajax = {
    /**
     * 初期設定
     */
    setup: function () {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            beforeSend: function (xhr, settings) {
                np_jp.lock()
                np_jp.removeHasError();
                //画面内にerror、またはwarningのメッセージがある場合は消す
                let $information_message = $('.information-message');
                if ($information_message.children('p').hasClass('icon_error') || $information_message.children('p').hasClass('icon_warning')) {
                    $information_message.hide();
                }
            },
            complete: function (xhr, textStatus) {

            },
        });
    },
};

/**
 * 初期表示処理
 */
$(document).ready(function () {
    ajax.setup();
});

/**
 * エラー時の処理
 */
$(document).ajaxError(function (event, jqXHR, settings, exception) {
    np_jp.unlock();

    // 強制リダイレクト処理
    let forcedRedirect = jqXHR.responseJSON.forced_redirect || null;
    if (forcedRedirect) {
        location.href = forcedRedirect;
    }

    let errors = jqXHR.responseJSON.errors || null;
    let pc_header_height = $('#nav-pc-scrolled').height();
    let sp_header_height = $('.header-sp').height();
    let header_height = pc_header_height > sp_header_height ? pc_header_height : sp_header_height;
    if (errors) {
        if (errors.messages) {
            np_jp.showMessages('error', errors);

        } else {
            np_jp.showMessages('warning', errors);
        }
        $('body, html').animate({scrollTop: $('.error_message').parent().offset().top - (header_height + 15)}, 200);

    } else if (jqXHR.status === 419) {
        np_jp.showTokenErrorMessages();
        $('body, html').animate({scrollTop: $('.error_message').parent().offset().top - (header_height + 15)}, 200);
    } else {
        np_jp.showServerErrorMessages(jqXHR.responseJSON.messages ?? jqXHR.responseJSON.message);
        $('body, html').animate({scrollTop: $('.error_message').parent().offset().top - (header_height + 15)}, 200);
    }
});
